/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import ModuleDefinitionModel from '../../Models/ModuleDefinitionModel';
import RouteModel from '../../Models/RouteModel';
import EmptyComponent from '../../Components/EmptyComponent';

class ProfileDefinition extends ModuleDefinitionModel {
  moduleName = 'ProfileModule';

  constructor() {
    super();
    this.routes.push(RouteModel.factory('/v5/profile', false, EmptyComponent));
  }

  getDisplayOptions = () => ({
    withHeader: false,
    withBackToolbar: true,
  });
}

export default ProfileDefinition;
