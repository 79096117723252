/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

export default class InterfaceActions {
  static RESIZE = 'RESIZE';
  static SERVER_ERROR = 'SERVER_ERROR';
  static LIKES_MODE = 'LIKES_MODE';
  static SELECTED_MODE = 'SELECTED_MODE';
  static OPEN_SETTINGS_DRAWER = 'OPEN_SETTINGS_DRAWER';
  static CLOSE_SETTINGS_DRAWER = 'CLOSE_SETTINGS_DRAWER';
  static TOGGLE_SETTINGS_DRAWER = 'TOGGLE_SETTINGS_DRAWER';
  static DRAWER_CHANGE_SETTINGS = 'DRAWER_CHANGE_SETTINGS';

  static chaneSettings(field, value) {
    return {
      type: InterfaceActions.DRAWER_CHANGE_SETTINGS,
      payload: {
        value,
        field,
      },
    };
  }

  static openSettingsDrawer() {
    return { type: InterfaceActions.OPEN_SETTINGS_DRAWER };
  }

  static closeSettingsDrawer() {
    return { type: InterfaceActions.CLOSE_SETTINGS_DRAWER };
  }

  static toggleSettingsDrawer() {
    return {type: InterfaceActions.TOGGLE_SETTINGS_DRAWER};
  }

        static createResizeAction(width,height) {
            return {
                type: this.RESIZE,
                width: width,
                height: height
            }
        }
        static createLikesModeAction() {
            return {
                type: this.LIKES_MODE,
            }
        }
        static createSelectedModeAction() {
            return {
                type: this.SELECTED_MODE,
            }
        }
}
