import isLocal from 'v4/core/functions/isLocal';
import Logger from '../../../v5/Core/Logger';

export default function () {
  if (
    isLocal()
    && process.env.NODE_ENV === 'development'
  ) {
    // eslint-disable-next-line prefer-rest-params
    Logger.info('Core.logger', { ...arguments });
    console.error(`H.E (${window.location.hostname})`, Object.values(arguments));
  }
}
