import { Helper } from '../../v4/global';
import axios from 'axios';
import { fetch } from 'whatwg-fetch';
import Actions from '.';

export default class FetchActions {


  static FETCH_START = 'FETCH_START';
  static FETCH_FAIL = 'FETCH_FAIL';
  static FETCH_END = 'FETCH_END';
  static FETCH_ERROR = 'FETCH_ERROR';
  static INITIAL_FETCH = 'INITIAL_FETCH';

  static RESOURSE_CART_INVALIDATE = 'fetchCartInvalidate'
  static RESOURSE_PROFILE_LOAD = 'fetchProfileLoad';

  static INIT_SETTINGS = 'INIT_SETTINGS';

  static createInitialFetchAction(name, value) {
    return {
      type: FetchActions.INITIAL_FETCH,
      payload: {
        name,
        value,
      },
    };
  }
  static createFetchPredefinedFilters(id,cb) {
    return (dispatch, getState) => {
      const { apiRoot } = getState().api;
      const { getPredefinedFilter } = getState().api.v3;
      var url = apiRoot+getPredefinedFilter;
      axios.post(url,{
        ID: id
      }).then(
        (res) => {
          var newfilter = JSON.parse(res.data.filter);
          dispatch(Actions.filter.createProductAddFilterAction(newfilter,null));
          cb();
        }
      )
    }
  }
  static createFetchStartAction(userData) {
    return (dispatch,getState) => {

      dispatch({
        type: FetchActions.FETCH_START,
        payload: userData
      })
    }
  }
  static createFetchFailAction(field,userData) {
    return (dispatch,getState) => {
      Helper.logError("FETCH_FAIL_ACTION_CREATOR");
      dispatch({
        type: FetchActions.FETCH_FAIL,
        payload: field,
        error: userData
      })
    }
  }
  static createFetchEndAction(userData) {
    return (dispatch,getState) => {

      dispatch({
        type: FetchActions.FETCH_END,
        payload: userData
      })
    }
  }

  static getFetch(url) {
    return fetch(url,{
      method: 'GET',
      mode: 'cors', // no-cors, cors, *same-origin
      credentials: 'include', // include, same-origin, *omit
    })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.json();
        }
      });
  }
}
