/**
 * Стандартная функция, которая позволяет получить диспетчер redux в компонент
 *
 * @param dispatch
 * @returns {{dispatch: *}}
 */

import {
  sendRestorePasswordRequest,
} from '../../../actions/authActions';
import { Actions, Helper } from '../../../global';


export function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatch,
    dispatchMultiple(actions, onSuccess, onFail) { dispatch(Actions.core.dispatchMultiple(actions, onSuccess, onFail)) },
    changeCheckoutFields(fields) {
      return dispatch(Actions.checkout.changeFields(fields));
    },
    checkout: (withError) => {
      dispatch(
        Actions.checkout.checkOut(withError),
      );
    },
    cart_changeQty: (item, qty) => {
      if (Helper.config.allowRealtimeReserve) {
        let amount = qty;
        if (item.EtoNabor == 1) {
          amount = Math.ceil(amount / item.KolichestvoVNabore);
        }
        dispatch(Actions.cart.addItemToCart(item.KodVnutrennii, amount));
      } else {
        dispatch(
          Actions.cart.addToCart({
            id: item.KodVnutrennii,
            price: item.Cena,
            quantity: qty,
          }),
        );
      }
    },
    createLikeAction: (...args) => dispatch(Actions.products.createLikeAction(...args)),
    createSelectProductAction:
      (...args) => dispatch(Actions.select.createSelectProductAction(...args)),
    productLoadNext: (...args) => dispatch(Actions.products.productLoadNext(...args)),
    createChangeCurrentItemAction:
      (...args) => dispatch(Actions.products.createChangeCurrentItemAction(...args)),
  };
}

export function mapAuthDispatchToProps(dispatch, ownProps) {
  return {
    dispatch: dispatch,
    login(data) {
      dispatch(Actions.profile.createLoginProfileAction(data));
    },
    register(data) { console.log('WWRT register action', data)},
    forgot(data) {
      dispatch(sendRestorePasswordRequest(data, data.onSuccess));
    },
    sendSMS(data) { console.log('WWRT sendSMS action', data)},
    clearErrors(data) { console.log('WWRT clearErrors action', data)},
    setError(field, text) { console.log('WWRT clearErrors action', field, text)},
  };
}
