/**
 * Контейнер для загрузчика, показывающий, что сейчас модуль загружается.
 * Для переопределения его стиля используется класс "loading-overlay-container" и "loading-overlay-container-process".
 * Внутри этого контейнера текст, который обернут в блок с классом "loading-overlay-container-child".
 * Для отображения текста используется механизм из Helper и блок текста Loadable::Error.
 */
import React from 'react';
import PropTypes from 'prop-types';

import AdvancedComponent from 'v3/Components/AdvancedComponent';

import Helper from 'Helper';

class LoadingOverlay extends AdvancedComponent {
  constructor(props) {
    super(props);
  }

  render() {
    super.render();
    const {
      text, textstyle, noopacity, textColor,
    } = this.props;
    Helper.statLog(this.constructor.name);
    let style = {};
    if (noopacity === true) {
      style.opacity = 1;
      style.zIndex = 100000;
    }
    if (textColor) {
      style.color = textColor;
    }
    if (textstyle) {
      style = { ...style, ...textstyle };
    }
    return (
      <div className="loading-overlay-container loading-overlay-container-process" style={style}>
        <div className="loading-overlay-container-child" style={style}>
          {text}
        </div>
      </div>
    );
  }
}

LoadingOverlay.defaultProps = {
  text: Helper.tr('Loading...', 'Loadable'),
};

LoadingOverlay.propTypes = {
  text: PropTypes.string,
};

export default LoadingOverlay;
