/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

export default class coreActions {
  static UPDATE_SETTINGS = 'UPDATE_SETTINGS';

  static dispatchMultiple = (actions, onSuccess, onFail) => (dispatch, getState) => {
    // console.log("WREST", onSuccess, onFail)
    // Promise.all(
    actions.map((action) => {
      switch (typeof action) {
        case 'function':
        case 'object':
          return dispatch(action);
        case 'string':
          return dispatch({ type: action });
        default:
          return null;
      }
    });
    // )
    //   .then(() => (onSuccess ? dispatch({ type: onSuccess }) : null))
    //   .catch(() => (onFail ? dispatch({ type: onFail }) : null));
  }
}
