/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

export default function initialLoadStructures() {
  return {
    type: 'FIRST_LOAD_STRUCTURES',
    payload: {
      request: {
        url: 'api/v7/structures/current',
      },
    },
  };
}
