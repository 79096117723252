/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import React from 'react';
import PropTypes from 'prop-types';

function EmptyComponent() {
  return (
    <div>
      Empty
    </div>
  );
}

EmptyComponent.propTypes = {};

export default EmptyComponent;
