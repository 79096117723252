import Helper from 'Helper';

export default class TestActions {
  static ACTION_TEST = 'ACTION_TEST';

  static createTestAction(userData) {
      return (dispatch,getState) => {
        Helper.logError("Test actions creator");
        dispatch({
          type: TestActions.ACTION_TEST,
          payload: userData
        })
      }
    }
  }
