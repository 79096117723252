/* eslint-disable no-underscore-dangle */
class ProductModel {
  _internalVariables = {
    refNum: null,
    article: null,
    brand: null,
    name: null,
    priceRegular: 0,
    priceRecommend: 0,
    priceCurrent: 0,
    inStock: 0,
    allowedToSale: false,
    barcode: null,
    images: [],
    subProducts: {},
    characteristics: {},
    relatedProducts: {},
    orderQuantity: 1,

    descriptionShort: null,
    descriptionFull: null,
    priceSupplier: null,
    forOrderLater: false,
    weight: 0,
    height: 0,
    width: 0,
    packLength: 0,
    parent: null,
  };

  set refNum(value) { this._internalVariables.refNum = value; }

  set article(value) { this._internalVariables.article = value; }

  set brand(value) { this._internalVariables.brand = value; }

  set name(value) { this._internalVariables.name = value; }

  set priceRegular(value) { this._internalVariables.priceRegular = value; }

  set priceRecommend(value) { this._internalVariables.priceRecommend = value; }

  set priceCurrent(value) { this._internalVariables.priceCurrent = value; }

  set inStock(value) { this._internalVariables.inStock = value; }

  set allowedToSale(value) { this._internalVariables.allowedToSale = value; }

  set barcode(value) { this._internalVariables.barcode = value; }

  set images(value) { this._internalVariables.images = value; }

  set subProducts(value) { this._internalVariables.subProducts = value; }

  set characteristics(value) { this._internalVariables.characteristics = value; }

  set relatedProducts(value) { this._internalVariables.relatedProducts = value; }

  set descriptionShort(value) { this._internalVariables.descriptionShort = value; }

  set descriptionFull(value) { this._internalVariables.descriptionFull = value; }

  set priceSupplier(value) { this._internalVariables.priceSupplier = value; }

  set forOrderLater(value) { this._internalVariables.forOrderLater = value; }

  set weight(value) { this._internalVariables.weight = value; }

  set height(value) { this._internalVariables.height = value; }

  set width(value) { this._internalVariables.width = value; }

  set packLength(value) { this._internalVariables.packLength = value; }
  set orderQuantity(value) { this._internalVariables.orderQuantity = value; }
  set parent(value) { this._internalVariables.parent = value; }

  get refNum() { return this._internalVariables.refNum; }

  get article() { return this._internalVariables.article; }

  get brand() { return this._internalVariables.brand; }

  get name() { return this._internalVariables.name; }

  get priceRegular() { return this._internalVariables.priceRegular; }

  get priceRecommend() { return this._internalVariables.priceRecommend; }

  get priceCurrent() { return this._internalVariables.priceCurrent; }

  get inStock() { return this._internalVariables.inStock; }

  get allowedToSale() { return this._internalVariables.allowedToSale; }

  get barcode() { return this._internalVariables.barcode; }

  get images() { return this._internalVariables.images; }

  get subProducts() { return this._internalVariables.subProducts; }

  get characteristics() { return this._internalVariables.characteristics; }

  get relatedProducts() { return this._internalVariables.relatedProducts; }

  get descriptionShort() { return this._internalVariables.descriptionShort; }

  get descriptionFull() { return this._internalVariables.descriptionFull; }

  get priceSupplier() { return this._internalVariables.priceSupplier; }

  get forOrderLater() { return this._internalVariables.forOrderLater; }

  get weight() { return this._internalVariables.weight; }

  get height() { return this._internalVariables.height; }

  get width() { return this._internalVariables.width; }

  get packLength() { return this._internalVariables.packLength; }

  get orderQuantity() { return this._internalVariables.orderQuantity; }
  get parent() { return this._internalVariables.parent; }

  getProperties() {
    return Object.keys(this._internalVariables);
  }

  normalize() {

  }

  updateProduct(product) {
    this.priceRegular = product.priceRegular;
    this.priceRecommend = product.priceRecommend;
    this.priceCurrent = product.priceCurrent;
    this.inStock = product.inStock;
    this.allowedToSale = product.allowedToSale;
    this.images = product.images;
    this.orderQuantity = product.orderQuantity;
    this.descriptionShort = product.descriptionShort;
    this.descriptionFull = product.descriptionFull;
    this.priceSupplier = product.priceSupplier;
    this.forOrderLater = product.forOrderLater;
    this.weight = product.weight;
    this.height = product.height;
    this.width = product.width;
    this.packLength = product.packLength;
  }

  addSubProduct(product) {
    this.subProducts[product.refNum] = product;
  }
}

export default ProductModel;