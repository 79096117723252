/* eslint-disable prefer-template */
/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

const base = (window.location.protocol ? window.location.protocol : 'http') + '//' + window.location.hostname;

export default {
  home: '/',
  loaderPage: '/loader',
  // Authentification and authorization
  auth: '/auth',
  // loginPage: '/auth/login',
  loginPage: base + '/secure',
  logoutPage: '/auth/logout',
  // registerPage: '/auth/register',
  registerPage: base + '/secure',
  restorePasswordPage: '/auth/restore',
  forbidden: '/auth/forbidden',
  requestPage: '/dropshipping',
  // Products and shopping cart
  productsPage: '/products',
  productsPageNoAuth: '/products/free/:filter',
  productsSelectedPage: '/products/selected/:selectedId',
  filtersPage: '/products/filters',
  shareFiltersPage: '/filters/share',
  setFiltersPage: '/setFilter/:id',
  cartPage: '/products/cart',
  checkoutPage: '/products/cart/checkout',
  paymentResultsPage: '/payments/result/:id',
  ignoreCheckoutPage: '/products/cart/checkoutIgnore',
  detailPage: '/products/detail/:id',
  teaserPage: '/teaser/create',
  // user profile
  profilePage: '/profile',
  ordersPage: '/profile/orders',
  // Content pages
  contentPage: '/content/:type/:slug',
  contactsPage: '/content/static/contacts',
  termsPage: '/content/static/terms',
  helpPage: '/content/static/help',
  helpPageD: '/content/static/help_d',
  sizesPage: '/content/static/sizes',
  aboutPage: '/content/static/about',
  tutorialPage: '/tutorial',
  // Service pages
  logPage: '/log',
  // Unnecessary
  likesPage: '/products/likes',
  testPage: '/test',
  // Dropshipping
  dropshipping: '/dropshipping'
};

const fullLinks = {
  // Неизвестные страницы отображаются пустыми. Нужно сделать или 404 или еще как
  home: 'http://testopt.localhost:3000/',
  loaderPage: 'http://testopt.localhost:3000/loader',
  // Authentification and authorization
  loginPage: 'http://testopt.localhost:3000/auth/login', // +
  logoutPage: 'http://testopt.localhost:3000/auth/logout', // +
  registerPage: 'http://testopt.localhost:3000/auth/register',  // +
  restorePasswordPage: 'http://testopt.localhost:3000/auth/restore', // +
  forbidden: 'http://testopt.localhost:3000/auth/forbidden', // +
  // Products and shopping cart
  productsPage: 'http://testopt.localhost:3000/products', // +

  productsPageNoAuth: '/products/free/:filter', // +
  productsSelectedPage: '/products/selected/:selectedId', // +

  filtersPage: 'http://testopt.localhost:3000/products/filters', // Для десктопа ???
  shareFiltersPage: 'http://testopt.localhost:3000/filters/share', // ???

  setFiltersPage: '/setFilter/:id', //+

  cartPage: 'http://testopt.localhost:3000/products/cart', // +
  checkoutPage: 'http://testopt.localhost:3000/products/cart/checkout', // +

  paymentResultsPage: '/payments/result/:id', // Нужно смотреть статусі платежа

  ignoreCheckoutPage: 'http://testopt.localhost:3000/products/cart/checkoutIgnore', // +
  detailPage: 'http://testopt.localhost:3000/products/detail', // Нужно определиться с id
  teaserPage: 'http://testopt.localhost:3000/teaser/create', // Ошибка
  // user profile
  profilePage: 'http://testopt.localhost:3000/profile', // +
  ordersPage: 'http://testopt.localhost:3000/profile/orders', // +
  // Content pages
  contentPage: '/content/:type/:slug', // Не работает на новом роутинге

  contactsPage: 'http://testopt.localhost:3000/content/static/contacts', // Не работает на новом роутинге
  termsPage: 'http://testopt.localhost:3000/content/static/terms', // Не работает на новом роутинге
  helpPage: 'http://testopt.localhost:3000/content/static/help', // Не работает на новом роутинге
  helpPageD: 'http://testopt.localhost:3000/content/static/help_d', // Не работает на новом роутинге
  sizesPage: 'http://testopt.localhost:3000/content/static/sizes', // Не работает на новом роутинге
  aboutPage: 'http://testopt.localhost:3000/content/static/about', // Не работает на новом роутинге
  tutorialPage: 'http://testopt.localhost:3000/tutorial', // Отправляет на каталог товаров
  // Service pages
  logPage: 'http://testopt.localhost:3000/log', // Почему-то отправляет назад
  // Unnecessary
  likesPage: 'http://testopt.localhost:3000/products/likes', // Странная страница
  testPage: 'http://testopt.localhost:3000/test',
}
