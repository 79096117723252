/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

export default function initialLoadDictionaries() {
  return {
    type: 'FIRST_LOAD_DICTIONARIES',
    payload: {
      request: {
        url: 'api/v7/dictionaries/start',
      },
    },
  };
}
