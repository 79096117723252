/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import * as loglevel from 'loglevel';
import remote from 'loglevel-plugin-remote';

const defaults = {
  url: `${window.location.protocol}//${window.location.hostname}/logger`,
  method: 'POST',
  headers: {},
  token: '',
  onUnauthorized: (failedToken) => {},
  timeout: 0,
  interval: 1000,
  level: 'trace',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  capacity: 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error'],
    depth: 3,
    excess: 0,
  },
  timestamp: () => new Date().getTime(),
  format: (log) => ({ ...log, host: window.location.hostname }),
};

class Logger {
  static log = {};

  static remoteApply = false;

  static init(name) {
    if (!window.iDealerRemoteLogApply) {
      const options = { ...defaults };
      remote.apply(loglevel, options);
      Logger.remoteApply = true;
      window.iDealerRemoteLogApply = true;
    }
    if (!Logger.log[name]) {
      Logger.log[name] = loglevel.getLogger(name);
      Logger.log[name].enableAll();
      loglevel.enableAll();
    }
    return Logger.log[name];
  }

  static trace(moduleName, msg) {
    const log = Logger.init(moduleName);
    msg = {
      message: 'Logger.loglevel',
      module: moduleName,
      data: msg,
    };
    log.trace('%j', msg);
  }

  static debug(moduleName, msg) {
    const log = Logger.init(moduleName);
    msg = {
      message: 'Logger.loglevel',
      module: moduleName,
      data: msg,
    };
    Logger.log[moduleName].debug('%j', msg);
  }

  static info(moduleName, msg) {
    const log = Logger.init(moduleName);
    msg = {
      message: 'Logger.loglevel',
      module: moduleName,
      data: msg,
    };
    log.info('%j', msg);
  }

  static warn(moduleName, msg) {
    const log = Logger.init(moduleName);
    msg = {
      message: 'Logger.loglevel',
      module: moduleName,
      data: msg,
    };
    log.warn('%j', msg);
  }

  static error(moduleName, msg) {
    const log = Logger.init(moduleName);
    msg = {
      message: 'Logger.loglevel',
      module: moduleName,
      data: msg,
    };
    log.error('%j', msg);
  }
}

export default Logger;
