import ProductModel from "./Models/ProductModel";

class ProductsFabric {
  static create = (json) => {
    const product = new ProductModel(json);
    Object.keys(json).map((property) => product[property] = json[property]);
    product.normalize();
    return product;
  }
}

export default ProductsFabric;