/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


export default class catalogActions {
  static CATALOG_VIEW_TOGGLE = "CATALOG_VIEW_TOGGLE";
  static CATALOG_VIEW_SHOW = "CATALOG_VIEW_SHOW";
  static CATALOG_VIEW_HIDE = "CATALOG_VIEW_HIDE";
  static CATALOG_SET_CURRENT = "CATALOG_SET_CURRENT";

  static filterCategories() {
    return (dispatch, getState) => {

      var dicts = getState().dictionary.group;
      var categories = getState().catalogStructure.basicCatalog;
      function catsum(cats) {
        return cats.reduce((acc,v) => acc+v.count,0);
      }
      function catwalk(cats) {
       return cats.map((v,i) => {
        if(v.subitems!==undefined) {
          // Walk subcats
          v.subitems = catwalk(v.subitems);
          v.count = catsum(v.subitems);
          return v;
        } else {
          var ok = Object.keys(v.filters.group);
          ok.map((vs,is) => {
            var cnt = dicts.filter((vv) => vv.name===vs);
            if(cnt.length>0) {
              cnt = parseInt(cnt[0].count,10);
            } else {
              cnt = 0;
            }
            // categories[i].filters.group.count = cnt;
            v.count = v.count===undefined?cnt:v.count+cnt;
            return v;
          })
        }
        return v;
      })
      }
      var cat = catwalk(categories);
      dispatch({
        type: "SET_CATEGORIES",
        payload: cat
      });

    }
  }

  static toggleCatalogView() {
    return {
      type: this.CATALOG_VIEW_TOGGLE
    };
  }
  static showCatalog() {
    return (dispatch,getState) => {
      // var cats = getState().catalogStructure.basicCatalog;
      // var dict = getState().dictionary.group;
      // var catslist = {};
      // var dictlist = {};
      // Object.keys(cats).map((v,i) => {
      //   var item = cats[v];
      //   if(item.subitems!==undefined) {
      //     Object.keys(item.subitems).map((v,i) => {
      //       Object.keys(item.subitems[v].filters.group).map((v,i) => {
      //         catslist[v]=false;
      //         return null;
      //       })
      //     });
      //   } else {
      //     Object.keys(item.filters.group).map((v,i) => {
      //       catslist[v]=false;
      //       return null;
      //     })
      //   }
      //   return null;
      // })
      // Object.keys(dict).map((v,i) => {
      //   dictlist[dict[v].name] = true;
      //   if(catslist[dict[v].name]!==undefined) {
      //     catslist[dict[v].name] = true;
      //   }
      // })
      // console.log("WWR",dictlist,catslist);
      dispatch({
        type: this.CATALOG_VIEW_SHOW
      });
    }
  }
  static hideCatalog() {
    return {
      type: this.CATALOG_VIEW_HIDE
    };
  }
}
