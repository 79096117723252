/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

class ModuleDefinitionModel {
  routes = [];

  moduleName = null;

  middlewares = [];

  actions = [];

  reducers = [];

  parts = {
    header: [],
    footer: [],
    drawers: [],
  };

  getDisplayOptions = () => ({
    withHeader: false,
    withBackToolbar: false,
  });
}

export default ModuleDefinitionModel;
