/* eslint-disable import/prefer-default-export */

/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

function initialLoadTranslations() {
  return {
    type: 'FIRST_LOAD_TRANSLATIONS',
    payload: {
      request: {
        url: 'api/v7/translations/all',
      },
    },
  };
}

function afterTranslationLoaded() {
  console.log('TRANSLATION LOADING END');
}

export {
  initialLoadTranslations,
  afterTranslationLoaded,
};
