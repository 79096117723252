/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

class ModuleRegistryException extends Error {
  constructor(message) {
    super();
    this.message = message;
  }
}

export default ModuleRegistryException;
