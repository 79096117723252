/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import Helper from 'Helper';
import Actions from "new/actions";
import axios from 'axios';

export default class selectActions {
        static SELECT_ADD_PRODUCT          = 'SELECT_ADD_PRODUCT';
  static CREATE_SELECTED_LIST_FINISH = "CREATE_SELECTED_LIST_FINISH";


  static createSelectsLink(items) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      axios.post(apiRoot+v3.createSelectsLink,{selectedItems: items})
        .then(resp => {
          dispatch({
            type: selectActions.CREATE_SELECTED_LIST_FINISH,
            payload: resp.data.listID,
          });
        })
        .catch(e => {
          dispatch({
            type: "LOG_FETCH",
            payload: {
              place: "Actions.filter.createSelectsLink",
              url: apiRoot+v3.createSelectsLink,
              body: { selectedItems: items },
              error: e,
            },
          });
        })
    }
  }

  static createSelectProductAction(refNum) {
    return (dispatch,getState) => {
      const {getCartData, apiRoot } = getState().api;

      dispatch({
        type: selectActions.SELECT_ADD_PRODUCT,
        payload: refNum,
      })
      return;
      var url = apiRoot+getCartData;
      var body = JSON.stringify(getState().cart);
      fetch(url,{
          method: "POST",
          mode: "cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include", // include, same-origin, *omit
          body: body,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
          },
          redirect: "follow", // manual, *follow, error
      })
      .then(resp => {
        if(resp.ok) {
          return resp.json();
        } else {
          if(navigator!==undefined && navigator.onLine) {
           Helper.logError("Wrong resp: ",url,body,resp);
          } else {
           Helper.logError("Wrong resp: offline",url,body,resp);
          }
        }
      })
      .catch(e => {
        if(navigator!==undefined && navigator.onLine) {
         Helper.logError("Server error: ",url,body,e);
        } else {
         Helper.logError("Server error: offline");
        }
      })
      .then(data => {
        if(data!==undefined && data.cartItems!==undefined) {
          Actions.products.normalize(data);
          var cart = getState().cart;
          var cost = 0;
          Object.keys(cart.items).map(v => {
            var item = data.cartItems[v];
            cost = cost + item.KolichestvoVNabore*cart.items[v]*item.Cena;
          })
          Object.keys(cart.minOrder).map(v => {
            var item = data.cartItems[v];
            cost = cost + item.MinZakaz*cart.minOrder[v]*item.Cena;
          });
          data.cost = cost;
          dispatch({
            type: selectActions.UPDATE_CART_ITEMS,
            payload: data,
          })
          //dispatch(cartActions.invalidateCart());
        }
      })
    }
  }
}
