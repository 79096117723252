/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import Helper from '../../Helper';
import Actions from '.';
import ActionCreators from '../ActionCreators';

export default class cartActions {
  static CART_LOADING_START = 'CART_LOADING_START';

  static CART_LOADING_SUCCESS = 'CART_LOADING_SUCCESS';

  static CART_LOADING_FAIL = 'CART_LOADING_FAIL';

  static CART_ADD_PRODUCT = 'CART_ADD_PRODUCT';

  static CART_INVALIDATE = 'CART_INVALIDATE';

  static CHECKOUT = 'CHECKOUT';

  static CART_DELETE_PRODUCT = 'CART_DELETE_PRODUCT';

  static CART_REMOVE_PRODUCT = 'CART_REMOVE_PRODUCT';

  static SHIPPING = 'SHIPPING';

  static CLEAN_ORDER_NUM = 'CLEAN_ORDER_NUM';

  static INTERFACE_CART_NOTIFICATION = 'INTERFACE_CART_NOTIFICATION';

  static UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';

  static CLEAR_CART_ITEMS = 'CLEAR_CART_ITEMS';

  static CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';

  static ORDER_ERROR = 'ORDER_ERROR';

  static CLEAR_ORDER = 'CLEAR_ORDER';

  static START_SEND_ORDER = 'START_SEND_ORDER';

  static SET_ITEM_COMMENT = 'SET_ITEM_COMMENT';

  static REMOVE_ITEM_COMMENT = 'REMOVE_ITEM_COMMENT';

  static ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';

  static CART_CLEAR = 'CART_CLEAR';

  static START_RESERVE_MODE = 'START_RESERVE_MODE';

  static SET_RESERVE_MODE = 'SET_RESERVE_MODE';

  static SET_RESERVE_MODE_SUCCESS = 'SET_RESERVE_MODE_SUCCESS';

  static SET_RESERVE_MODE_FAIL = 'SET_RESERVE_MODE_FAIL';

  static TOGGLE_CURRENT_ORDER_RESERVE_MODE = 'TOGGLE_CURRENT_ORDER_RESERVE_MODE';

  static ADD_TO_CART_PUSH_ITEM = 'ADD_TO_CART_PUSH_ITEM';

  static ADD_TO_CART_ADD_ITEM = 'ADD_TO_CART_ADD_ITEM';

  /**
CART_ADD_PRODUCT
CART_REMOVE_PRODUCT
ADD_MIN_ORDER
REM_MIN_ORDER
CART_DELETE_PRODUCT
DEL_MIN_ORDER
*/

  static addItemToCart(item, qty) {
    return (dispatch, getState) => {
      const { cartItems } = getState().api.v7;
      dispatch({
        type: cartActions.ADD_ITEM_TO_CART,
        payload: {
          request: {
            url: cartItems,
            method: 'POST',
            data: {
              refNum: item,
              amount: qty,
              type: 1,
            },
          },
        },
      });
    };
  }

  static clearCart() {
    return (dispatch, getState) => {
      const { clearCart } = getState().api.v7;
      dispatch({
        type: cartActions.CART_CLEAR,
        payload: {
          request: {
            url: clearCart,
            method: 'DELETE',
          },
        },
      });
    };
    // return {
    //   type: cartActions.CLEAR_CART_ITEMS,
    // };
  }

  static createUpdateCartItemsAction() {
    return (dispatch, getState) => {
      const { getCartData, apiRoot } = getState().api;
      const url = apiRoot + getCartData;
      const currentCart = { ...getState().cart };
      currentCart.virtualItems = currentCart.virtualItems.serialize();
      const body = JSON.stringify(currentCart);
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        body,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          if (navigator !== undefined && navigator.onLine) {
            Helper.logError('Wrong resp: ', url, body, resp);
          } else {
            Helper.logError('Wrong resp: offline', url, body, resp);
          }
        })
        .catch((e) => {
          if (navigator !== undefined && navigator.onLine) {
            Helper.logError('Server error: ', url, body, e);
          } else {
            Helper.logError('Server error: offline');
          }
        })
        .then((data) => {
          if (data !== undefined && data.cartItems !== undefined) {
            Actions.products.normalize(data);
            const { cart } = getState();
            let cost = 0;
            Object.keys(cart.items).map((v) => {
              console.log(v, data.cartItems[v]);
              const item = data.cartItems[v];
              cost += item.KolichestvoVNabore * cart.items[v] * item.Cena;
              return null;
            });
            Object.keys(cart.minOrder).map((v) => {
              const item = data.cartItems[v];
              cost += item.MinZakaz * cart.minOrder[v] * item.Cena;
              return null;
            });
            data.cost = cost;
            dispatch({
              type: cartActions.UPDATE_CART_ITEMS,
              payload: data,
            });
            // dispatch(cartActions.invalidateCart());
            dispatch(cartActions.recalculateCartCost());
            // console.log("FETCH1", data);
          }
        });
    };
  }

  static recalculateCartCost() {
    return (dispatch, getState) => {
      const { cart } = getState();
      const { cartItems } = getState().products;
      let cost = 0;
      let count = 0;
      Object.keys(cart.items).map((v) => {
        const item = cartItems[v];
        if (item) {
          if (item.EtoNabor == 1) {
            count += item.KolichestvoVNabore * cart.items[v];
            cost += (item.KolichestvoVNabore * cart.items[v]) * item.Cena;
          } else {
            count += cart.items[v];
            let price = 0;
            if (item.Cena <= 0) {
              const parent = cartItems[item._parent];
              if (parent) {
                price = parent.Cena;
              }
            } else {
              price = item.Cena;
            }
            cost += (item.KolichestvoVNabore * cart.items[v]) * price;
          }
        }
      });
      Object.keys(cart.minOrder).map((v) => {
        const item = cartItems[v];
        if (item) {
          let price = 0;
          let qty = item.MinZakaz;
          if (item.Cena <= 0) {
            const parent = cartItems[item._parent];
            if (parent) {
              price = parent.Cena;
              qty = parent.MinZakaz;
            }
          } else {
            price = item.Cena;
          }
          count += cart.minOrder[v] * qty;
          cost += (qty * cart.minOrder[v]) * price;
        }
      });
      if (cart.virtualItems) {
        // console.log("WREST", cart.virtualItems.items);
        Object.keys(cart.virtualItems.items).map((v) => {
          Object.keys(cart.virtualItems.items[v].asPlainObject()).map((vItem) => {
            // console.log("WREST-1", vItem, cart.virtualItems.items[v].getItemsCount())

            const item = cartItems[vItem];
            if (item) {
              let price = 0;
              if (item.Cena <= 0) {
                const parent = cartItems[item._parent];
                if (parent) {
                  price = parent.Cena;
                }
              } else {
                price = item.Cena;
              }
              count += cart.virtualItems.items[v].inCart;
              cost += cart.virtualItems.items[v].inCart * price;
            }
          });
        });
      }
      dispatch({
        type: this.CART_INVALIDATE,
        cartUpdate: {
          cost,
          count,
        },
      });
    };
  }

  static createCartNotification() {
    return {
      type: this.INTERFACE_CART_NOTIFICATION,
      payload: Helper.tr('Your cart is out of date. Refresh please', 'interface'),
    };
  }

  static createRemCartNotification() {
    return {
      type: this.INTERFACE_CART_NOTIFICATION,
    };
  }

  static cleanOrderNum() {
    return {
      type: this.CLEAN_ORDER_NUM,
    };
  }

  static unauthCheckOut(data) {
    return (dispatch, getState) => {
      // const { profile, cart } = getState();
      // const {createOrder, apiRoot } = getState().api;
    };
  }

  static addQuikToCart(item) {
    return (dispatch, getState) => {
      dispatch({
        type: this.CART_ADD_PRODUCT,
        payload: item,
        mst: true,
      });
    };
  }

  static addToCart(action) {
    return (dispatch, getState) => {
      const refNum = typeof action.id === 'object' ? action.id.KodVnutrennii : action.id;
      const qty = action.quantity;
      const { price } = action;
      let item = getState().products.items[refNum];
      if (item === undefined) {
        item = getState().products.nenabor[refNum];
      }
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        if (item && item.EtoNabor === 1) {
          dispatch(Actions.cart.addItemToCart(refNum, 1));
        } else {
          dispatch(Actions.cart.addItemToCart(refNum, qty));
        }
      } else {
        dispatch({
          type: this.CART_ADD_PRODUCT,
          payload: {
            id: refNum,
            quantity: qty,
            price: price || item.Cena,
          },
        });
      }
    };
  }

  static removeFromCart(action) {
    return (dispatch, getState) => {
      const refNum = action.id;
      const qty = action.quantity;
      const { price } = action;
      let item = getState().products.items[refNum];
      if (item === undefined) {
        item = getState().products.nenabor[refNum];
      }
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        if (item && item.EtoNabor === 1) {
          dispatch(Actions.cart.addItemToCart(refNum, -1));
        } else {
          dispatch(Actions.cart.addItemToCart(refNum, -1 * qty));
        }
      } else {
        dispatch({
          type: this.CART_REMOVE_PRODUCT,
          payload: {
            id: refNum,
            quantity: qty,
            price: price || item.Cena,
          },
        });
      }
    };
  }

  static deleteFromCart(item) {
    console.log(item);
    return (dispatch, getState) => {
      const refNum = item.id;
      const { price } = item;
      const { cart } = getState();
      let qty = 0;
      if (cart.items[refNum] !== undefined) {
        qty = cart.items[refNum];
      } else {
        qty = cart.minOrder[refNum] !== undefined ? cart.minOrder[refNum] : 0;
      }
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        if (qty > 0) {
          dispatch(Actions.cart.addItemToCart(refNum, -1 * qty));
        }
      } else {
        item.price = parseInt(item.price, 10);
        item.quantity = parseInt(item.quantity, 10);
        dispatch({
          type: this.CART_DELETE_PRODUCT,
          payload: {
            id: refNum,
            quantity: qty,
            price: price || item.price,
          },
        });
      }
    };
  }

  static invalidateCart() {
    return (dispatch, getState) => {
      const { cart, profile } = getState();
      if (
        cart.cartLoaded === false
      ) return;
      if ((profile.isLogged || Helper.config.allowUnauthorized) && !fetch.fetchCartInvalidate) {
        // Пользователь авторизирован
        // Сохраняем корзину на сервер

        const { getSaveCart, apiRoot } = getState().api;
        const url = apiRoot + getSaveCart;
        dispatch(
          Actions.fetch.createFetchStartAction(
            Actions.fetch.RESOURSE_CART_INVALIDATE,
          ),
        );
        ActionCreators.test.console('test');
        const currentCart = { ...cart };
        currentCart.virtualItems = currentCart.virtualItems.serialize();
        fetch(url, {
          method: 'POST',
          mode: 'cors', // no-cors, cors, *same-origin
          credentials: 'include', // include, same-origin, *omit
          body: JSON.stringify({
            cart: currentCart,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            Actions.products.normalize(data);
            dispatch(cartActions.recalculateCartCost(data, profile));
            console.log('FETCH2', data);
            localStorage.removeItem('NL_cart');
          })
          .then((data) => dispatch(
            Actions.fetch.createFetchEndAction(
              Actions.fetch.RESOURSE_CART_INVALIDATE,
            ),
          ));
      } else {
        // Пользователь не авторизирован
        // Сохраняем корзину в локальное хранилище
        localStorage.setItem('NL_cart', JSON.stringify(
          cart,
        ));
        dispatch({
          type: this.CART_INVALIDATE,
          payload: profile,
        });
      }
    };
  }

  static shipping(v) {
    return {
      type: this.SHIPPING,
      name: v.name,
      value: v.value,
    };
  }

  static toggleReserveMode() {
    return (dispatch, getState) => {
      const { toggleReserveMode } = getState().api.v7;
      dispatch({
        type: cartActions.START_RESERVE_MODE,
      });
      dispatch({
        type: cartActions.SET_RESERVE_MODE,
        payload: {
          request: {
            url: toggleReserveMode,
            method: 'POST',
          },
        },
      });
    };
  }

  static getReserveMode() {
    return (dispatch, getState) => {
      const { getReserveMode } = getState().api.v7;
      dispatch({
        type: cartActions.SET_RESERVE_MODE,
        payload: {
          request: {
            url: getReserveMode,
            method: 'GET',
          },
        },
      });
    };
  }

  static toggleReserveModeForCurrentOrder() {
    return {
      type: cartActions.TOGGLE_CURRENT_ORDER_RESERVE_MODE,
    };
  }

  static pushItemToCart(item) {
    return {
      type: cartActions.ADD_TO_CART_PUSH_ITEM,
      payload: item,
    };
  }

  static addCart(item, amount, parent) {
    return (dispatch, getState) => {
      console.log('!!!', item, amount, parent);
      if (item !== undefined) {
        if (parent) dispatch(this.pushItemToCart(parent));
        dispatch(this.pushItemToCart(item));
        if (amount > 0) {
          if (amount !== 1) {
            dispatch(Actions.minOrder.createAddMinOrderAction(
              item.KodVnutrennii,
              1,
              item.Cena,
            ));
          } else {
            dispatch(this.addToCart({
              id: item.KodVnutrennii,
              quantity: item.EtoNabor === 1 ? 1 : amount,
              price: item.Cena,
            }));
          }
        }
        if (amount < 0) {
          if (amount !== -1) {
            dispatch(Actions.minOrder.createDelMinOrderAction(item.KodVnutrennii,
              1,
              item.Cena));
          } else {
            dispatch(this.removeFromCart({
              id: item.KodVnutrennii,
              quantity: item.EtoNabor === 1 ? 1 : -1 * amount,
              price: item.Cena,
            }));
          }
        }
        dispatch(this.recalculateCartCost());
      }
    };
  }

  static removeItem(item, amount, parent) {
    return (dispatch, getState) => {
      dispatch(this.deleteFromCart({
        id: item.KodVnutrennii,
        quantity: amount,
        price: item.Cena,
      }));
    };
  }
}
