/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { Actions, getConfig } from '../../v4/global';
import Helper from "../../Helper";

export default class MinOrderActions {
  static ADD_MIN_ORDER = 'ADD_MIN_ORDER';

  static REM_MIN_ORDER = 'REM_MIN_ORDER';

  static DEL_MIN_ORDER = 'DEL_MIN_ORDER';

  static createAddMinOrderAction(itemID, quantity, price, parent) {
    return (dispatch, getState) => {
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        let item = getState().products.items[itemID];
        if (!item) item = getState().products.nenabor[itemID];
        if (!item) item = getState().products.cartItems[itemID];
        if (!item) {
          // error item not found
        }
        if (item) {
          dispatch(Actions.cart.addItemToCart(itemID, quantity));
        }
      } else {
        dispatch({
          type: this.ADD_MIN_ORDER,
          payload: {
            itemID: itemID,
            quantity: quantity,
            price: price,
            parent: parent
          }
        })
        const {apiRoot, v3} = getState().api;
        var url = apiRoot + v3.addToCart + "/1/" + itemID + "/1";
        Actions.fetch.getFetch(url)
          .then(resp => console.log(resp))
      }
    }
  }
  static createRemMinOrderAction(itemID,quantity,price) {
    return (dispatch,getState) => {
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        let item = getState().products.items[itemID];
        if (!item) item = getState().products.nenabor[itemID];
        if (!item) item = getState().products.cartItems[itemID];
        if (!item) {
          // error item not found
        }
        if (item) {
          dispatch(Actions.cart.addItemToCart(itemID, -1*quantity));
        }
      } else {
        dispatch({
          type: this.REM_MIN_ORDER,
          payload: {
            itemID: itemID,
            quantity: quantity,
            price: price
          }
        })
        const {apiRoot, v3} = getState().api;
        var url = apiRoot + v3.remFromCart + "/1/" + itemID + "/1";
        Actions.fetch.getFetch(url)
          .then(resp => console.log(resp))
      }
    }
  }
  static createDelMinOrderAction(itemID,quantity,price) {
    return (dispatch,getState) => {
      if (Helper.config.allowRealtimeReserve && getState().modes.reserveMode) {
        let item = getState().products.items[itemID];
        if (!item) item = getState().products.nenabor[itemID];
        if (!item) item = getState().products.cartItems[itemID];
        if (!item) {
          // error item not found
        }
        if (item) {
          dispatch(Actions.cart.addItemToCart(itemID, -1*quantity));
        }
      } else {
        dispatch({
          type: this.DEL_MIN_ORDER,
          payload: {
            itemID: itemID,
            quantity: parseInt(quantity, 10),
            price: parseInt(price, 10)
          }
        })
        const {apiRoot, v3} = getState().api;
        var url = apiRoot + v3.delFromCart + "/1/" + itemID;
        Actions.fetch.getFetch(url)
          .then(resp => console.log(resp))
      }
    }
  }
}
