/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

import axios from "axios";
import Helper from "../../../Helper";
axios.defaults.withCredentials = true

export function loadTranslations() {
  axios.get(Helper.getRootApi()+"translations")
    .then(resp => {
      Helper.translations = Object.assign(Helper.translations,resp.data.i18n);
    });

}