/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

export function sendRestorePasswordRequest(data, onSuccess) {
  return (dispatch, getState) => {
    const { getForgot, apiRoot } = getState().api;
    const url = apiRoot + getForgot;
    const formData = new FormData();
    formData.append('login', data.login);
    fetch(url, {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      credentials: 'include', // include, same-origin, *omit
      body: formData,
    })
      .then((resp) => resp.json())
      .then((result) => {
        onSuccess(result);
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createForgotProfileAction',
              url,
              error,
            },
          });
        }
      });
  };
}

export default {
  sendRestorePasswordRequest,
};
