/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

export default class RouteModel {
  path = '';

  exact = '';

  component = null;

  static factory(path, exact, component) {
    const newRoute = new RouteModel();
    newRoute.path = path;
    newRoute.exact = exact;
    newRoute.component = component;
    return newRoute;
  }
}
