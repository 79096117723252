/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import axios from 'axios';
import { Actions } from '../../../v4/global';

export default function initialLoadSettings() {
  // return {
  //   type: 'FIRST_LOAD_SETTINGS',
  //   payload: {
  //     request: {
  //       url: 'api/v7/settings/current',
  //     },
  //   },
  // };
  return (dispatch) => axios.get('http://testopt.localhost/api/v7/settings/current')
    .catch((e) => null)
    .then((resp) => {
      if (resp && resp.data && resp.data.settings) {
        dispatch({
          type: Actions.core.UPDATE_SETTINGS,
          payload: {
            settings: resp.data.settings,
          },
        });
      }
    });
}
