/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import ModuleRegistryException from './Exceptions/ModuleRegistryException';
import ModuleDefinitionModel from './Models/ModuleDefinitionModel';

class ModuleRegistry {
  static list = {};

  static init() {
    console.log('ModuleRegistry.list', ModuleRegistry.getRoutes());
  }

  static registerModule(moduleDefinition) {
    if (moduleDefinition instanceof ModuleDefinitionModel) {
      if (ModuleRegistry.hasModule(moduleDefinition.moduleName)) {
        throw new ModuleRegistryException(`module ${moduleDefinition.moduleName} already added to registry`);
      }
      ModuleRegistry.list[moduleDefinition.moduleName] = moduleDefinition;
    }
  }

  static hasModule(moduleName) {
    return ModuleRegistry.list[moduleName] !== undefined;
  }

  static getModuleDefinition(moduleName) {
    return ModuleRegistry.list[moduleName];
  }

  static getRoutes() {
    const routes = [];
    Object.keys(ModuleRegistry.list).map((v) => routes.push(...ModuleRegistry.list[v].routes));
    // Object.keys(ModuleRegistry.list).map((v) => routes = routes.concat(routes, ModuleRegistry.list[v].routes));
    return routes;
  }
}

export default ModuleRegistry;
