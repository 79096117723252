/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import Helper from "Helper";
import { fetch } from "whatwg-fetch";

import Actions from "new/actions";

export default class dictionaryActions {
  static DICTIONARY_ADD = "DICTIONARY_ADD";

  static createLoadDictionariesAction() {
    return (dispatch, getState) => {
      const { apiRoot, getDictionaries } = getState().api;

      var url = apiRoot + getDictionaries;
      fetch(url, {
        method: "GET",
        mode: "cors", // no-cors, cors, *same-origin
        credentials: "include" // include, same-origin, *omit
      })
        .then(resp => resp.json())
        .then(data => {
          Helper.sendEvent(getState().profile.ID,3,"Loaded");
          Actions.products.normalize(data);
          dispatch({
            type: this.DICTIONARY_ADD,
            payload: data
          });
          if(getState().fetch.initialLoading) {
            // dispatch(Actions.products.createLoadProductsAction());
            dispatch(Actions.fetch.createInitialFetchAction(
              'initialLoadingStage',
              3
            ));
            dispatch(Actions.catalog.filterCategories());
            dispatch(Actions.fetch.createInitialFetchAction(
              'initialLoading',
              false
            ));
          }
        })
        .catch(error => {
          Helper.sendEvent(getState().profile.ID,3,"Error: "+JSON.stringify(error));
          dispatch({
            type: "LOG_FETCH",
            payload: {
              place: "createLoadDictionariesAction",
              url: url,
              error: error
            }
          });
        });
    };
  }
}
