/* eslint-disable object-shorthand */
/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

import ReactGA from 'react-ga';
import { Helper, Events } from '../../global';
import Logger from '../../../v5/Core/Logger';

export function initGA() {
  // Check if google analytics connected
  // if (!window.dataLayer || !window.dataLayer.find) {
  //   throw new Error('Google Analytics/Tag ID not defined');
  // }
  if (!window.gaData) {
    throw new Error('Google Analytics/Tag was not loaded');
  }
  const ids = Object.keys(window.gaData);
  if (ids.length === 0) {
    throw new Error('Google Analytics/Tag ID not defined');
  }
  const gaID = ids[0];
  if (!gaID) {
    throw new Error('Google Analytics/Tag ID not set');
  }
  // Init ReactGA
  ReactGA.initialize(gaID);
  if (window.iDealer) {
    ReactGA.set({
      campaignName: window.iDealer.utms.utm_campaign,
      campaignSource: window.iDealer.utms.utm_source,
      campaignMedium: window.iDealer.utms.utm_medium,
      campaignKeyword: window.iDealer.utms.utm_term,
      campaignContent: window.iDealer.utms.utm_content,
    });
  }
}

// https://test.opt.moda/?utm_campaign=test_campaign&utm_source=test_source&utm_medium=test_medium&utm_term=test_term&utm_content=test_content

export function sendGoogleAnalyticsPageView(
  page,
  onSuccess = null,
  onError = null,
) {
  const myevent = {
    page: page,
  };
  try {
    initGA();
    ReactGA.pageview(page);
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(myevent);
    } else {
      myevent.session = Helper.getCookie('PHPSESSID');
      Helper.sendEvent(0, Events.EVENT_PAGEVIEW, myevent);
    }
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError('Google.analytics', e, myevent);
    } else {
      Logger.info('Google.analytics', { msg: e.message, err: e });
    }
  }
}

export function sendGoogleAnalyticsEvent(
  category,
  action,
  label,
  onSuccess = null,
  onError = null,
) {
  const event = {
    category,
    action,
    label,
  };
  try {
    initGA();
    ReactGA.event(event);
    if (onSuccess !== null && typeof onSuccess === 'function') {
      onSuccess(event);
    } else {
      event.session = Helper.getCookie('PHPSESSID');
      Helper.sendEvent(0, Events.EVENT_WONDER, event);
    }
  } catch (e) {
    if (onError !== null && typeof onError === 'function') {
      onError('Google.analytics', e, event);
    } else {
      Logger.info('Google.analytics', { msg: e.message, err: e });
    }
  }
}
