/* eslint-disable prefer-template,object-shorthand */
/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

// import Helper from 'Helper';
import ReactGA from 'react-ga';
import { fetch } from 'whatwg-fetch';

import Actions from '.';
import Helper from '../../Helper';
import { initGA } from '../../v4/core/functions/initGA';

export default class ProfileActions {
  static SET_PROFILE = 'SET_PROFILE';

  static SET_CLIENTS = 'SET_CLIENTS';

  static PROFILE_RESTORE_PASS = 'PROFILE_RESTORE_PASS';

  static PROFILE_LOGOUT = 'PROFILE_LOGOUT'

  static PROFILE_CHANGE_CURRENCY = 'PROFILE_CHANGE_CURRENCY';

  static PROFILE_REMOTE_FORGOT = 'PROFILE_REMOTE_FORGOT';

  static START_LOAD_PROFILE = 'START_LOAD_PROFILE';

  static SUCCESS_ADD_SUGGESTION = 'SUCCESS_ADD_SUGGESTION';

  static SUGGESTION_LOAD = 'SUGGESTION_LOAD';

  static SAVE_PROFILE = 'SAVE_PROFILE';

  static createRegisterProfileAction(userData, history) {
    return (dispatch, getState) => {
      const { getProfileRgister, apiRoot } = getState().api;
      const url = apiRoot + getProfileRgister;
      const formData = new FormData();
      formData.append('login', userData.login);
      formData.append('password', userData.password);
      formData.append('userName', userData.userName);
      formData.append('userSoname', userData.userSoname);
      formData.append('email', userData.email);
      formData.append('reCaptcha', userData.reCaptcha);

      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: formData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          initGA();
          ReactGA.event({
            category: 'User',
            action: 'Register',
            label: data.isLogged ? 'Success' : 'Fail',
          });
          Actions.products.normalize(data);
          dispatch({
            type: this.SET_PROFILE,
            payload: { ...data, registerError: data.error, error: null },
          });
          if (data.isLogged) {
            history.push(Helper.links.productsPage);
          }
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createRegisterProfileAction',
              url,
              error,
            },
          });
        });
    };
  }

  static createChangeCurrencyAction(currency) {
    return (dispatch, getState) => {
      const { changeCurrency, apiRoot } = getState().api;
      const url = apiRoot + changeCurrency + '/' + currency;
      fetch(url, {
        method: 'GET',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
      })
        .then((resp) => resp.json())
        .then((data) => {
          Actions.products.normalize(data);
          dispatch({
            type: this.PROFILE_CHANGE_CURRENCY,
            payload: currency,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createChangeCurrencyAction',
              url,
              error,
            },
          });
        });
    };
  }

  static createAddSuggestionAction(clientID, suggestion, name = '') {
    return (dispatch, getState) => {
      const { apiRoot, v3 } = getState().api;

      const url = apiRoot + v3.addSuggestion;
      const respBody = null;
      const respBodyText = null;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: JSON.stringify({
          clientID: clientID,
          suggestion: suggestion,
          name: name,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          dispatch({
            type: this.SUCCESS_ADD_SUGGESTION,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch(
            Actions.fetch.createFetchFailAction(
              Actions.fetch.SUGGESTION_LOAD,
              {
                place: 'createLoadProfileAction',
                url: url,
                msg: error.message,
                body: respBody,
                text: respBodyText,
                error: error,
              },
            ),
          );
        });
    };
  }

  static createLoadProfileAction() {
    return (dispatch, getState) => {
      const { apiRoot, v3 } = getState().api;

      const url = apiRoot + v3.getProfile;
      const respBody = null;
      const respBodyText = null;
      dispatch(
        Actions.fetch.createFetchStartAction(
          Actions.fetch.RESOURSE_PROFILE_LOAD,
        ),
      );
      fetch(url, {
        method: 'GET',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
      })
        .then((resp) => resp.json())
        .then((data) => {
          Actions.products.normalize(data);
          dispatch({
            type: this.SET_PROFILE,
            payload: data.profile,
            likes: data.likes,
            likes_images: data.likes_images,
            likes_items: data.likes_items,
            clients: data.clients,
          });
          dispatch(
            Actions.fetch.createFetchEndAction(
              Actions.fetch.RESOURSE_PROFILE_LOAD,
            ),
          );
          console.log('FETCH5', data);
        })
        .catch((error) => {
          dispatch(
            Actions.fetch.createFetchFailAction(
              Actions.fetch.RESOURSE_PROFILE_LOAD,
              {
                place: 'createLoadProfileAction',
                url: url,
                msg: error.message,
                body: respBody,
                text: respBodyText,
                error: error,
              },
            ),
          );
        });
    };
  }

  static createLoginProfileAction(loginData) {
    return (dispatch, getState) => {
      dispatch({
        type: ProfileActions.START_LOAD_PROFILE,
      });
      const { apiRoot, getLogin } = getState().api;
      const url = apiRoot + getLogin;
      const formData = new FormData();
      formData.append('login', loginData.login);
      formData.append('password', loginData.password);
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: formData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          initGA();
          ReactGA.event({
            category: 'User',
            action: 'Log In',
            label: data.isLogged ? 'Success' : 'Fail',
          });
          Helper.sendEvent(
            data.isLogged === true ? data.ID : 0,
            8,
            data.isLogged === true ? 'Success' : 'Fail: ' + loginData.login + ' : ' + loginData.password,
          );
          if (data.isLogged === true) {
            Actions.products.normalize(data);
            dispatch(Actions.products.createLoadProductsAction());
          }
          dispatch({
            type: this.SET_PROFILE,
            payload: data,
            clients: data.clients,
          });
          console.log('FETCH6', data);
        })
        .catch((error) => {
          initGA();
          ReactGA.event({
            category: 'User',
            action: 'Log In',
            label: 'server fail',
          });
          Helper.sendEvent(
            0,
            8,
            'Login Error: ' + loginData.login + ' : ' + JSON.stringify(error),
          );
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createLoginProfileAction',
              url: url,
              error: error,
            },
          });
        });
    };
  }

  static createForgotProfileAction(login) {
    return (dispatch, getState) => {
      const { getForgot, apiRoot } = getState().api;
      const url = apiRoot + getForgot;
      const formData = new FormData();
      formData.append('login', login);
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: formData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          Actions.products.normalize(data);
          dispatch({
            type: this.PROFILE_RESTORE_PASS,
            payload: data,
          });
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: 'LOG_FETCH',
              payload: {
                place: 'createForgotProfileAction',
                url: url,
                error: error,
              },
            });
          }
        });
    };
  }

  static createLogOutAction() {
    return (dispatch, getState) => {
      const { getLogout, apiRoot } = getState().api;
      const { cart } = getState();
      const url = apiRoot + getLogout;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: JSON.stringify({
          cart: cart,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          Actions.products.normalize(data);
          dispatch({
            type: this.PROFILE_LOGOUT,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createLogOutAction',
              url: url,
              error: error,
            },
          });
        });
    };
  }
}
