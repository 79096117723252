import { bindActionCreators } from 'redux';
import axios from 'axios';
import get from 'lodash/get';
import Actions from './actions';
import Helper from '../Helper';

class ActionCreators {
  static init(dispatch) {
    ActionCreators.cart = bindActionCreators(ActionCreators.cart, dispatch);
    ActionCreators.test = bindActionCreators(ActionCreators.test, dispatch);
    ActionCreators.profile = bindActionCreators(ActionCreators.profile, dispatch);
    ActionCreators.products = bindActionCreators(ActionCreators.products, dispatch);
  }

  static products = {
    loadByIds: (ids) => (dispatch, getState) => {
      // Загружаем товары по списку с бекенда
      axios.post(`${Helper.getRootApi()}api/v7/products/byids`, {
        products: ids,
      })
        .then((resp) => {
          const products = get(resp, 'data.searchResult');
          const links = get(resp, 'data.links');
          const ost = get(resp, 'data.ost');
          const images = get(resp, 'data.searchResultImages');
          const list = products.map((product) => product.KodVnutrennii);
          if (list) {
            dispatch({
              type: 'ADD_PRODUCTS_TO_SELECTED',
              products,
              images,
              list,
              links,
              ost,
            });
            // alert(`Loading ${list.join(', ')}`);
            // добавляем товары в список отобранных
          }
        });
    },
  }

  static test = {
    console(payload) {
      return {
        type: 'TEST',
        ...payload,
      };
    },
  }

  static profile = {
    save(data) {
      console.log('!');
      return {
        type: Actions.profile.SAVE_PROFILE,
        payload: {
          request: {
            url: '/api/v7/profile/save',
            method: 'POST',
            data: {
              profile: data,
            },
          },
        },
      };
    },
  }

  static cart = {
    invalidate(payload) {

    },
    incVirtual(refNum) {
      return {
        type: Actions.virtualItemsActions.VIRTUAL_ITEM_INC,
        refNum,
      };
    },
    decVirtual(refNum) {
      return {
        type: Actions.virtualItemsActions.VIRTUAL_ITEM_DEC,
        refNum,
      };
    },
    remVirtual(refNum) {
      return {
        type: Actions.virtualItemsActions.VIRTUAL_ITEM_REM,
        refNum,
      };
    },
    addVirtual(items, cost) {
      return Actions
        .virtualItemsActions
        .addVirtualItemToCart(
          items.filter((v) => v.KolichestvoOstatok > 0),
          1,
          cost,
        );
    },
  }
}

export default ActionCreators;
