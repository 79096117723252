/*
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */
if (process.env.NODE_ENV === 'development') {
  module.exports = {
    allowShowReduxDevTools: false,
    allowShowReduxLogger: false,
    allowShowReduxLoggerConfig: {
      duration: true,
      diff: true,
      collapsed: true,
    },
    allowShowRenders: false,
    allowShowInternalRenders: false,
    allowShowInternalConstruct: false,
    restrictFromConstruct: [],
    allowHtml5Routing: true,
    allowV8: false,
  };
} else {
  module.exports = {
    allowShowReduxDevTools: false,
    allowShowReduxLogger: false,
    allowShowReduxLoggerConfig: {
      duration: true,
      diff: true,
      collapsed: true,
    },
    allowShowRenders: false,
    allowShowInternalRenders: false,
    allowShowInternalConstruct: false,
    restrictFromConstruct: [],
    allowHtml5Routing: true,
    allowV8: false,
  };
}
