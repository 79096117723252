import axios from 'axios';
import Actions from '.';
import Helper from '../../Helper';

export default class checkoutActions {
  static CHANGE_FIELD = 'CHANGE_FIELD';

  static CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';

  static CLEAR_ORDER = 'CLEAR_ORDER';

  static ORDER_ERROR = 'ORDER_ERROR';

  static START_SEND_ORDER = 'START_SEND_ORDER';

  static CHECKOUT = 'CHECKOUT';

  static clearOrder() {
    return {
      type: checkoutActions.CLEAR_ORDER,
    };
  }

  static changeFields(fields) {
    return {
      type: checkoutActions.CHANGE_FIELD,
      payload: fields,
    };
  }

  static createFetchPredefinedFilters(id, cb) {
    return (dispatch, getState) => {
      const { apiRoot } = getState().api;
      const { getPredefinedFilter } = getState().api.v3;
      const url = apiRoot + getPredefinedFilter;
      axios.post(url, {
        ID: id,
      }).then(
        (res) => {
          const newfilter = JSON.parse(res.data.filter);
          dispatch(Actions.filter.createProductAddFilterAction(newfilter, null));
          cb();
        },
      );
    };
  }

  static checkOut(withError) {
    return (dispatch, getState) => {
      const { currencies } = getState().root;
      const { profile, cart } = getState();
      const { createOrder, apiRoot } = getState().api;
      const state = getState();
      const { currentOrderReserveMode, reserveMode } = state.modes;
      const data = {
        phone: state.checkout.phone === null ? state.profile.phone : state.checkout.phone,
        name: state.checkout.name === null ? state.profile.name : state.checkout.name,
        secondname: state.checkout.secondname === null
          ? state.profile.secondname
          : state.checkout.secondname,
        soname: state.checkout.soname === null ? state.profile.soname : state.checkout.soname,
        email: state.checkout.email === null ? state.profile.email : state.checkout.email,
        paymentType: state.checkout.paymentType,
        deliveryType: state.checkout.deliveryType,
        deliveryRegion: state.checkout.deliveryRegion,
        deliveryArea: state.checkout.deliveryArea,
        deliveryTown: state.checkout.deliveryCity,
        deliveryStreet: state.checkout.deliveryStreet,
        deliveryBuilding: state.checkout.deliveryBuilding,
        deliveryCorp: state.checkout.deliveryCorp,
        deliveryFlat: state.checkout.deliveryFlat,
        deliveryZip: state.checkout.deliveryZip,
        deliveryWarehouse: state.checkout.deliveryWarehouse,
        extendedDelivery: {
          type: {
            country: 'UA',
            carrier: 'NovaPoshta',
          },
          deliveryTown: state.checkout.deliveryTown,
          deliveryWarehouse: state.checkout.deliveryWarehouse,
          deliveryStreet: state.checkout.deliveryStreet,
        },
        /** ** Additional ** */
        prim: state.checkout.prim,
        withError,
      };
      const virtualItems = cart.virtualItems.asPlainObject();
      const cartItems = { ...cart.items };
      // console.log('WREST-VI', virtualItems, cartItems);
      const count = Object.keys(cart.items).length
        + Object.keys(cart.minOrder).length
        + Object.keys(virtualItems).length;
      if (count === 0) {
        return null;
      }
      Object.keys(virtualItems).map((refNum) => {
        cartItems[refNum] = cartItems[refNum] ? cartItems[refNum] + virtualItems[refNum] : virtualItems[refNum];
        return cartItems[refNum];
      });
      // console.log('WREST-VI', virtualItems, cartItems);
      // return;
      const checkoutData = {
        ...data,
        profile,
        shippingType: data.deliveryType,
        cost: cart.cost,
        items: cartItems,
        minOrder: cart.minOrder,
        itemComments: cart.itemComments,
      };
      if (profile.userGroup > 0 && profile.userGroup < 5) {
        checkoutData.manager = profile.ID;
      }
      const url = apiRoot + createOrder;
      dispatch({
        type: checkoutActions.CLEAR_ORDER_ERROR,
      });
      dispatch({
        type: checkoutActions.START_SEND_ORDER,
      });
      const senddata = {
        checkoutData,
        mode: 0,
      };
      if (currentOrderReserveMode || Helper.config.allowAutomacomcomticReserve) {
        senddata.mode = 1;
      } else if (reserveMode) {
        senddata.mode = 2;
      }
      axios.post(url, senddata)
        .then((resp) => {
          if (typeof resp.data === 'object') {
            Actions.products.normalize(resp.data);
            dispatch({
              type: this.CHECKOUT,
              payload: resp.data,
            });
            if (profile.userGroup > 0 && profile.userGroup < 5) {
              dispatch(Actions.checkout.changeFields({
                name: null,
                soname: null,
                secondname: null,
                phone: null,
                email: null,
              }));
            }
          } else {
            dispatch({
              type: 'LOG_FETCH',
              payload: {
                place: 'checkOut',
                url,
              },
            });
            dispatch({
              type: 'ORDER_ERROR',
              payload: {
                place: 'checkOut',
                url,
              },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'checkOut',
              url,
              error,
            },
          });
          dispatch({
            type: 'ORDER_ERROR',
            payload: {
              place: 'checkOut',
              url,
              error,
            },
          });
        });
      return true;
    };
  }

  static autocompleteSelect(v) {
    return (dispatch/* , getState */) => {
      dispatch({
        type: checkoutActions.CHANGE_FIELD,
        payload: {
          phone: v.phone,
          name: v.userName,
          soname: v.userSoname,
        },
      });
    };
  }
}
