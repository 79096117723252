export const VIRTUAL_ITEM_SINGLE = 'VIRTUAL_ITEM_SINGLE';
export const VIRTUAL_ITEM_PACK = 'VIRTUAL_ITEM_PACK';
export const VIRTUAL_ITEM_MINORDER = 'VIRTUAL_ITEM_MINORDER';

class VirtualCartItem {
  type = VIRTUAL_ITEM_SINGLE;

  inCart = 0;

  items = {};

  quantity = 1;

  getRefNum() {
    if (this.items) {
      return Object.keys(this.items).join('_');
    }
  }

  appendProduct(id) {
    this.items[id] = true;
  }

  removeProduct(id) {
    delete this.items[id];
  }

  getProductsCount() {
    return Object.keys(this.items).length;
  }

  getItemsCount() {
    if (this.items) {
      return this.inCart * this.quantity * Object.keys(this.items).length;
    }
    return 0;
  }

  asPlainObject() {
    const result = { ...this.items };
    if (this.items) {
      Object
        .keys(this.items)
        .map((itemId) => {
          result[itemId] = this.inCart;
        });
    }
    return result;
  }

  serialize() {
    return {
      type: this.type,
      inCart: this.inCart,
      items: this.items,
      quantity: this.quantity,
    };
  }

  unserialize(data) {
    this.type = data.type;
    this.inCart = data.inCart;
    this.items = data.items;
    this.quantity = data.quantity;
  }
}

export default VirtualCartItem;
