import axios from 'axios';
import { Helper, Actions } from '../../v4/global';
import { loadTranslations } from '../../v4/core/api/actions';

import { initialLoadTranslations } from './initial/transllations';
import initialLoadSettings from './initial/settings';
import initialLoadStructures from './initial/structures';
import initialLoadDictionaries from './initial/dictionaries';
import initialLoadProfile from './initial/profile';
import initialLoadCart from './initial/cart';

export default class initialLoader {
  static start() {
    return (dispatch, getState) => {
      Promise.all([
        dispatch(initialLoader.initialLoad()),
        dispatch(initialLoadTranslations()), // translations
        dispatch(initialLoadSettings()), // settings
        dispatch(initialLoadStructures()), // structures
        dispatch(initialLoadDictionaries()), // dictionaries
        dispatch(initialLoadProfile()), // profile
        dispatch(initialLoadCart()), // cart
        // old
        dispatch(Actions.cart.createUpdateCartItemsAction()),
      ])
        .then(() => console.log('END'));
      // dispatch(initialLoader.initialLoad());
      // axios.get('http://testopt.localhost/api/v7/settings/current')
      //   .then((resp) => {
      //     dispatch({
      //       type: Actions.core.UPDATE_SETTINGS,
      //       payload: {
      //         settings: resp.data.settings,
      //       },
      //     });
      //   });
      const filter = window.location.href.match(/.*\/filter\/(\d+)/);
      if (filter && filter[1] !== undefined) {
        dispatch(
          Actions.fetch.createInitialFetchAction(
            'startPage',
            `${window.location.origin}/setFilter/${filter[1]}`,
          ),
        );
      }

      dispatch(
        Actions.interface.createResizeAction(
          window.innerWidth,
          window.innerHeight,
        ),
      );
      // dispatch(initialLoader.createInitialLoadAction());
    };
  }

  static initialLoad() {
    return (dispatch, getState) => {
      // Get Settings
      const { apiRoot, getProfile, v3 } = getState().api;
      dispatch({
        type: 'INITIAL_TRANSLATIONS_LOADING',
      });
      axios.get(`${apiRoot}translations`)
        .then((resp) => {
          Helper.translations = Object.assign(Helper.translations, resp.data.i18n);
          dispatch({
            type: 'INITIAL_TRANSLATIONS_LOADED',
            payload: resp.data,
          });
          dispatch({
            type: 'INITIAL_TRANSLATIONS_LOADING_SUCCESS',
          });
          axios.get(apiRoot + v3.getProfile)
            .then((resp) => {
              Helper.sendEvent(resp.data.profile.ID, 2, resp.data.profile.isLogged === true ? 'Logged' : 'Not logged');
              if (resp.data.profile.isLogged) {
                // Пользователь залогинен
                dispatch(
                  {
                    type: 'INITIAL_USER_ARE_LOGGED',
                  },
                );
                dispatch(Actions.cart.getReserveMode());
              } else {
                // Пользователь не залогинен
                dispatch(
                  {
                    type: 'INITIAL_USER_ARE_NOT_LOGGED',
                  },
                );
              }
              dispatch({
                type: Actions.profile.SET_PROFILE,
                payload: { ...resp.data.profile, content: resp.data.content },
                likes: resp.data.likes,
                likes_images: resp.data.likes_images,
                likes_items: resp.data.likes_items,
                clients: resp.data.clients,
              });
              // console.log('FETCH4', resp.data);
              const payload = resp.data.structure;
              payload.type = Actions.fetch.INIT_SETTINGS;
              dispatch(payload);
              dispatch(
                Actions.fetch.createInitialFetchAction('initialLoadingStage', 1),
              );
              dispatch(Actions.dictionary.createLoadDictionariesAction());
            })
            .catch((e) => {
              Helper.sendEvent(0, 2, `Load Profile Error: ${JSON.stringify(e)}`);
              dispatch({
                type: Actions.fetch.FETCH_ERROR,
                payload: 'Network error. Try later',
              });
            });
        })
        .catch((e) => {
          Helper.sendEvent(0, 2, `Load Profile Error: ${JSON.stringify(e)}`);
          dispatch({
            type: Actions.fetch.FETCH_ERROR,
            payload: 'Network error. Try later',
          });
        });
    };
  }

  // static createInitialLoadAction() {
  //   return (dispatch, getState) => {
  //     const { apiRoot, getProfile, v3 } = getState().api;
  //     // fetch profile
  //     var url = apiRoot + v3.getProfile;
  //     fetch(url, {
  //       method: "GET",
  //       mode: "cors", // no-cors, cors, *same-origin
  //       credentials: "include" // include, same-origin, *omit
  //     })
  //       .then(resp => resp.json())
  //       .then(data => {
  //         Helper.sendEvent(data.profile.ID,2,data.profile.isLogged===true?"Logged":"Not logged");
  //         Actions.products.normalize(data);
  //         dispatch({
  //           type: Actions.profile.SET_PROFILE,
  //           payload: data.profile,
  //           likes: data.likes,
  //           likes_images: data.likes_images,
  //           likes_items: data.likes_items,
  //           clients: data.clients
  //         });
  //         var payload = data.structure;
  //         payload.type = Actions.fetch.INIT_SETTINGS;
  //         dispatch(payload);
  //         dispatch(
  //           Actions.fetch.createInitialFetchAction("initialLoadingStage", 1)
  //         );
  //         // if(data.profile.isLogged===true) {
  //         // Загружаем остальное
  //         dispatch(Actions.dictionary.createLoadDictionariesAction());
  //         // }
  //       })
  //       .catch(e => {
  //         Helper.sendEvent(0,2,"Load Profile Error: "+JSON.stringify(e));
  //         dispatch({
  //           type: Actions.fetch.FETCH_ERROR,
  //           payload: "Network error. Try later"
  //         })
  //       });
  //     // fetch Dictionaries
  //     // fetch cart
  //     // Orders
  //     // Likes
  //   };
  //
  //
  // }
}
