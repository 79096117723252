export default {
  localhost: {
    apiRoot: 'http://localhost/',
  },
  '192.168.0.20': {
    apiRoot: 'http://testopt.localhost/',
  },
  '192.168.0.4': {
    apiRoot: 'https://test.opt.moda/',
  },
  'opt.localhost': {
    apiRoot: 'http://testopt.localhost/',
  },
  'tv.localhost': {
    apiRoot: 'http://tv.localhost/',
  },
  'drop.localhost': {
    apiRoot: 'http://drop.localhost/',
  },
  'kid.localhost': {
    apiRoot: 'http://kid.localhost/',
  },
  'testopt.localhost': {
    apiRoot: 'http://testopt.localhost/',
  },
};
