/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import Actions from 'new/actions';
import { fetch } from 'whatwg-fetch';

import Helper from 'Helper';

import ProductModel from 'v4/models/Product';

export default class filterActions {
  static PRODUCT_REMOVE_FILTER = 'PRODUCT_REMOVE_FILTER';

  static PRODUCT_INVALIDATE = 'PRODUCT_INVALIDATE';

  static PRODUCT_ADD_FILTER = 'PRODUCT_ADD_FILTER';

  static PRODUCT_CLEAN_FILTER = 'PRODUCT_CLEAN_FILTER';

  static PRODUCT_SET_FILTER = 'PRODUCT_SET_FILTER';

  static PRODUCT_LOAD_FILTER = 'PRODUCT_LOAD_FILTER';

  static PRODUCT_SAVE_FILTER_START = 'PRODUCT_SAVE_FILTER_START';

  static PRODUCT_SAVE_FILTER_STOP = 'PRODUCT_SAVE_FILTER_STOP';

  static PRODUCT_SAVE_FILTER_ERROR = 'PRODUCT_SAVE_FILTER_ERROR';

  static PRODUCT_SAVE_FILTER_SAVED = 'PRODUCT_SAVE_FILTER_SAVED';

  static PRODUCT_SAVE_FILTER_FILE_SUCCESS = 'PRODUCT_SAVE_FILTER_FILE_SUCCESS';

  static SET_BREADCRUMB = 'SET_BREADCRUMB';

  static ADD_MANY_TO_SELECTED = 'ADD_MANY_TO_SELECTED';

  static filterSaved(filter) {
    return (dispatch, getState) => {
      dispatch({
        type: filterActions.PRODUCT_SAVE_FILTER_SAVED,
        payload: filter,
      });
    };
  }

  static saveFile(id, file) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      dispatch({
        type: filterActions.PRODUCT_SAVE_FILTER_START,
      });
      const url = apiRoot + v3.savePredefinedFilterFile;
      const data = new FormData();
      data.append('file', file);
      data.append('id', id);
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        // headers: {
        //   "Content-Type": "application/json; charset=utf-8"
        // },
        redirect: 'follow', // manual, *follow, error

        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_FILE_SUCCESS,
            payload: data.url,
          });
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_STOP,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_ERROR,
          });
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  static saveText(id, text) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      dispatch({
        type: filterActions.PRODUCT_SAVE_FILTER_START,
      });
      const url = apiRoot + v3.savePredefinedFilterText;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          id,
          text,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_STOP,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_ERROR,
          });
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  static saveSelected(filter) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      dispatch({
        type: filterActions.PRODUCT_SAVE_FILTER_START,
        payload: filter,
      });
      const url = apiRoot + v3.savePredefinedFilterAselected;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          filters: filter,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          // dispatch({
          //   type: filterActions.PRODUCT_SAVE_FILTER_STOP,
          //   payload: data,
          // });
          // dispatch({
          //   type: filterActions.ADD_MANY_TO_SELECTED,
          //   payload: data.selectedItems,
          // });
          dispatch({
            type: 'ADD_PRODUCTS_TO_SELECTED',
            list: data.selectedItems,
          });
          // console.log('RES:', data.selectedItems.length);
        })
        .catch((error) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_ERROR,
          });
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  static saveFilter(filter) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      dispatch({
        type: filterActions.PRODUCT_SAVE_FILTER_START,
        payload: filter,
      });
      const url = apiRoot + v3.savePredefinedFilter;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          filter,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_STOP,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: filterActions.PRODUCT_SAVE_FILTER_ERROR,
          });
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  // http://localhost:3000/#/setFilter/12

  static productLoadFilter(id) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      const url = apiRoot + v3.getPredefinedFilter;

      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          ID: id,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          const newfilter = JSON.parse(data.filter);
          Helper.logError('Filter from predefined', newfilter);
          dispatch(
            Actions.filter.createProductAddFilterAction(newfilter, null, false),
          );
          dispatch({
            type: this.PRODUCT_LOAD_FILTER,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  /**
   * Удаление фильтра из одной секции
   *
   * @param string section
   * @returns Thunk
   */
  static productCleanFilter(section) {
    return (dispatch, getState) => {
      dispatch({
        type: this.PRODUCT_CLEAN_FILTER,
        payload: {
          section,
        },
      });
      dispatch(this.productInvalidate());
    };
  }
  // createProductSetFilterAction

  static createProductSetBreadcrumb(b) {
    return (dispatch, getState) => {
      dispatch({
        type: Actions.filter.SET_BREADCRUMB,
        payload: b,
      });
    };
  }

  /**
   * Заменяет фильтр на переданный
   *
   * @param string section
   * @param array items
   * @returns Thunk
   */
  static createProductSetFilterAction(section, items) {
    return (dispatch, getState) => {
      dispatch({
        type: this.PRODUCT_REMOVE_FILTER,
      });
      dispatch({
        type: Actions.catalog.CATALOG_SET_CURRENT,
        payload: section,
      });
      dispatch(this.productInvalidate());
      dispatch(Actions.catalog.toggleCatalogView());
    };
  }

  /**
   * Добавляет один фильтр в секцию
   *
   * @param string section
   * @param array items
   * @returns Thunk
   */
  static createProductAddFilterAction(section, items, scroll = null) {
    return (dispatch, getState) => {
      dispatch({
        type: this.PRODUCT_ADD_FILTER,
        payload: {
          section,
          items,
        },
      });
      dispatch(this.productInvalidate(scroll));
    };
  }

  /**
   * Удаляет все фильтры
   *
   * @returns Thunk
   */
  static createProductRemoveFilterAction() {
    return (dispatch, getState) => {
      dispatch({
        type: this.PRODUCT_REMOVE_FILTER,
      });
      dispatch(this.productInvalidate());
    };
  }

  static productLoadSelected(selId, cb) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      const url = `${apiRoot + v3.getSelectedItems}/${selId}`;

      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          selId,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          const models = {};
          Actions.products.parseProducts(data, models);
          Actions.products.normalize(data, getState());
          window.scrollTo(0, 1);
          // console.log("WWRT scroll zerro",Helper.positionY);
          dispatch({
            type: Actions.products.REMOTE_PRODUCTS_ADD,
            payload: data,
            products: models,
          });
          cb(data.allowShowPrices);
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }

  static productInvalidate(scroll = true) {
    return (dispatch, getState) => {
      const { getItems, apiRoot } = getState().api;
      const url = apiRoot + getItems;
      const filters = getState().products.currentfilter;

      filters.allowSalePacks = Helper.config.allowSalePacks;
      // var filters = getState().products.currentfilter;//currentGroup
      const cf = getState().catalogStructure.currentGroup;
      if (cf !== null && filters.group.length === 0) {
        filters.group = [].concat(filters.group, Object.keys(cf));
      }
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          filters,
          start: 0,
          pagesize: getState().products.pageSize,
          cart: getState().cart.items,
          minOrder: getState().cart.minOrder,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          const models = {};
          Actions.products.parseProducts(data, models);
          Actions.products.normalize(data, getState());
          console.log('INVALIDATE SCROLL:', scroll, Helper.positionY);
          if (!scroll) {
            Helper.positionY = 0;
          }
          window.scrollTo(0, Helper.positionY);
          dispatch({
            type: Actions.products.REMOTE_PRODUCTS_ADD,
            payload: data,
            products: models,
          });
          dispatch(Actions.cart.recalculateCartCost());
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productInvalidate',
              url,
              error,
            },
          });
        });
    };
  }
}
