/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import Helper from '../../Helper';
import Actions from '.';

export default class pricelistsActions {
  static PRICELIST_SEND_REQUEST = 'PRICELIST_SEND_REQUEST';
  static PRICELIST_SEND_REQUEST_SUCCESS = 'PRICELIST_SEND_REQUEST_SUCCESS';
  static PRICELIST_SEND_REQUEST_FAIL = 'PRICELIST_SEND_REQUEST_FAIL';

  static sendRequest(data) {
    return (dispatch, getState) => {
      const {pricelists: {sendRequest}} = getState().api.v7
      dispatch({
        type: pricelistsActions.PRICELIST_SEND_REQUEST,
        payload: {
          request: {
            url: sendRequest,
            method: 'POST',
            data,
          },
        },
      });
    }
  }
}
