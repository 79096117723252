/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import interfaceActions from './interfaceActions';
import profileActions from './profileActions';
import dictionaryActions from './dictionaryActions';
import productsActions from './productsActions';
import cartActions from './cartActions';
import filterActions from './filterActions';
import minOrderActions from './minOrderActions';
import testActions from './testActions';
import fetchActions from './fetchActions';
import selectActions from './selectActions';
import checkoutActions from './checkoutActions';
import catalogActions from './catalogActions';
import initialLoader from './initialLoader';
import coreActions from './coreActions';
import pricelistsActions from './pricelists';
import virtualItemsActions from './virtualItemsActions';

export default class Actions {
        static interface = interfaceActions;

        static profile= profileActions;

        static dictionary= dictionaryActions;

        static products= productsActions;

        static cart= cartActions;

        static filter= filterActions;

        static minOrder= minOrderActions;

        static test= testActions;

        static fetch= fetchActions;

        static select= selectActions;

        static checkout= checkoutActions;

        static catalog= catalogActions;

        static initial= initialLoader;

        static core= coreActions;

        static pricelists= pricelistsActions;

        static virtualItemsActions = virtualItemsActions;
}
