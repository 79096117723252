import {appServerKey, urlB64ToUint8Array} from "registerServiceWorker";
import Helper from "Helper";

export default function() {
  if (typeof Notification !== "undefined") {
    Notification.requestPermission(function(status) {
      Helper.logError("Notification permission status:", status);
    });
  }
  if (
    "serviceWorker" in navigator &&
    "getRegistrations" in navigator.serviceWorker
  ) {
    console.log("serviceWorker",navigator.serviceWorker);
    navigator.serviceWorker
      .getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          console.log("Registration",registration);
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(appServerKey)
            })
            .then(function(subscription) {
              if (!subscription) {
                // Set appropriate app states.
                Helper.logError("Subsr true");
                return;
              }
              Helper.logError("O: Subscr endpoint", subscription.endpoint);
            });
          //        registration.unregister();
        }
      })
      .catch(function(err) {
        Helper.logError("Service Worker registration failed: ", err);
      });

  }
}