/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import VirtualCartItem from '../../models/VirtualCartItem';
import Actions from './index';

export default class virtualItemsActions {
  static VIRTUAL_ITEM_ADD = 'VIRTUAL_ITEM_ADD';

  static VIRTUAL_ITEM_INC = 'VIRTUAL_ITEM_INC';

  static VIRTUAL_ITEM_DEC = 'VIRTUAL_ITEM_DEC';

  static VIRTUAL_ITEM_REM = 'VIRTUAL_ITEM_REM';

  static addVirtualItemToCart(items, count, price) {
    return (dispatch, getState) => {
      const virtualItem = new VirtualCartItem();
      items.map((item) => {
        virtualItem.appendProduct(item.KodVnutrennii);
        dispatch({
          type: Actions.cart.ADD_TO_CART_PUSH_ITEM,
          payload: item,
        });
      });
      virtualItem.quantity = 1;
      virtualItem.inCart = count;
      dispatch({
        type: virtualItemsActions.VIRTUAL_ITEM_ADD,
        payload: virtualItem,
        count: items.length,
        cost: items.length * price,
      });
      dispatch(
        Actions.cart.invalidateCart(),
      );
    };
  }
}
