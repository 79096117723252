import React from 'react';

import Helper from 'Helper';

class AdvancedPureComponent extends React.PureComponent {
  static whyDidYouRender = true

  constructor(props) {
    super(props);
    if (Helper.debug.allowShowInternalConstruct) {
      Helper.statLog(`Construct PureComponent: ${this.constructor.name}`);
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Helper.logError(error, errorInfo);
  }

  render() {
    if (Helper.config.allowShowInternalRenders) {
      Helper.statLog(`Render Pure Component: ${this.constructor.name}`);
    }
    return null;
  }
}

export default AdvancedPureComponent;
